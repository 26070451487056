.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  /* allow items to be arrange in column for moobile devices, Burger Builder, Checkout */
  flex-flow: column;
  align-items: center;
  height: 100%;
}

/* adjusted for general desktop devices.  */
@media (min-width: 500px) {
  .NavigationItems {
    flex-flow: row;
  }
}
